import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
var axios = require('axios');

class RenderCareer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            careerData: null
        }
    }
    componentWillMount() {
        let locationArray = window.location.pathname.split("/")
        let careerId = locationArray[locationArray.length - 1];
        var config = {
            method: 'get',
            url: `https://cdn.contentful.com/spaces/dmsh6kty6h8d/environments/master/entries/${careerId}?access_token=dK31oWHhdvpUSbz2iahqW4YTcuK6F6d_y_FH1o2K66Q`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                this.setState({
                    careerData: response.data
                })
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            });

    }
    render() {
        let blogData = null, headingData = null;
        if (this.state.careerData) {

            blogData = <section className="section gray-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="p-30px md-p-25px box-shadow white-bg border-radius-5 m-30px-b">
                                {documentToReactComponents(this.state.careerData.fields.description)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            headingData = <section className="bg-center bg-cover bg-fiexd effect-section" id={this.state.careerData.sys.id} style={{ backgroundImage: "url(/static/img/bg-1.jpg)" }}>
                {/* <div className="mask dark-g-bg opacity-7"></div> */}
                <div className="mask theme-bg opacity-8"></div>
                <div className="container">
                    <div className="row screen-65 justify-content-center align-items-center p-100px-tb">
                        <div className="col-lg-10 text-center m-50px-t">
                            <h1 className="display-4 font-w-600 white-color m-25px-b">{this.state.careerData.fields.heading}</h1>

                            {typeof this.state.careerData.fields.subHeading === "object" ?
                                this.state.careerData.fields.subHeading.map(function (innerData, index) {
                                    return (<p className="lead m-0px white-color-light" key={innerData}>{innerData}</p>)
                                }) :
                                <p className="lead m-0px white-color-light">{this.state.careerData.fields.subHeading}</p>}

                            {this.state.careerData.fields.tags ? <div className="nav justify-content-center">
                                {
                                    this.state.careerData.fields.tags.map(function (innerData, index) {
                                        return <span className="yellow-bg dark-color p-5px-tb p-10px-lr border-radius-15 font-small font-w-700 m-5px" key={innerData}>{innerData}</span>
                                    })
                                }
                            </div> : null}

                            <div className="m-25px-t"><Link class="m-btn m-btn-radius m-btn-theme2nd" to={{ "pathname": this.state.careerData.fields.link }} target={"_blank"}>Apply</Link></div>

                        </div>
                    </div>
                </div>
            </section>
        }

        return (
            <>
                {headingData}
                {blogData}
            </>
        )
    }
};

export default RenderCareer;