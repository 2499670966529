import React, { Component } from 'react';
import Moment from 'react-moment';
import getFeaturedImage from "../helper";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import RenderImage from './RenderImage';
var axios = require('axios');


const options = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            return <RenderImage imageId={node.data.target.sys.id} />
        }
    }
};

class RenderBlog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            blogData: null
        }
    }
    componentWillMount() {
        let locationArray = window.location.pathname.split("/")
        let blogId = locationArray[locationArray.length - 1];
        var config = {
            method: 'get',
            url: `https://cdn.contentful.com/spaces/dmsh6kty6h8d/environments/master/entries/${blogId}?access_token=dK31oWHhdvpUSbz2iahqW4YTcuK6F6d_y_FH1o2K66Q`,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                getFeaturedImage(response.data.fields.featuredImage.sys.id, 'backgroundImage');
                this.setState({
                    blogData: response.data
                })
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            });

    }
    render() {
        // let locationArray = window.location.pathname.split("/")
        // let blogId = locationArray[locationArray.length - 1];
        let blogData = null, headingData = null;
        if (this.state.blogData) {
            blogData = <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="nav p-25px-b">
                                <span className="dark-color font-w-600"><i className="fas fa-calendar-alt "></i>{` `}<Moment format="DD MMM, YYYY">{this.state.blogData.fields.date}</Moment></span>
                                <span className="dark-color font-w-600 m-15px-l"><i className="far fa-folder-open"></i>{` `}{this.state.blogData.fields.category}</span>
                            </div>
                            {documentToReactComponents(this.state.blogData.fields.body, options)}
                            <div class="nav tag-cloud p-25px-t">
                                {
                                    this.state.blogData.fields.tags.map(function (innerData, index) {
                                        return <a href="." key={innerData}>{innerData}</a>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="card m-35px-t">
                                <div className="card-header bg-transparent">
                                    <span className="h5 m-0px font-w-600 dark-color">Tags</span>
                                </div>
                                <div className="p-20px">
                                    <div class="nav tag-cloud p-25px-t">
                                        {
                                            this.state.blogData.fields.tags.map(function (innerData, index) {
                                                return <a href="." key={innerData}>{innerData}</a>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card m-35px-t">
                                <div className="card-header bg-transparent">
                                    <span className="h5 m-0px font-w-600 dark-color">Recent Posts</span>
                                </div>
                                <div className="list-group list-group-flush">
                                    <a href="#" className="list-group-item list-group-item-action d-flex p15px-tb">
                                        {/* <div>
                                            <div className="avatar-50 border-radius-5">
                                                <img src="/static/img/500x500.jpg" title="" alt="" />
                                            </div>
                                        </div>
                                        <div className="p-15px-l">
                                            <p className="m-0px">You don't want Traveling as your enemy!</p>
                                        </div>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            // style={{ backgroundImage: "url(/static/img/1600x900.jpg)" }}
            headingData = <section className="bg-center bg-cover bg-fiexd effect-section" id={this.state.blogData.fields.featuredImage.sys.id}>
                <div className="mask dark-g-bg opacity-7"></div>
                <div className="container">
                    <div className="row screen-65 justify-content-center align-items-center p-100px-tb">
                        <div className="col-lg-10 text-center m-50px-t">
                            <h1 className="display-4 font-w-600 white-color m-25px-b">{this.state.blogData.fields.title}</h1>
                            <p className="lead m-0px white-color-light">{this.state.blogData.fields.slug}</p>
                            <div className="d-flex align-items-center m-25px-t justify-content-center text-left">
                                <div>
                                    <div className="avatar-50 border-radius-50">
                                        <img src="static/img/500x500.jpg" title="" alt="" />
                                    </div>
                                </div>
                                {/* <div className="p-15px-l">
                                <p className="h6 font-w-600 white-color m-0px">Nancy Bayers</p>
                                <small className="white-color-light">Co-Founder</small>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
        return (
            <>
                {headingData}
                {blogData}
            </>
        )
    }
};

export default RenderBlog;