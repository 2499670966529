import React, { Component } from 'react';
import Typed from 'react-typed';
import configData from './config.json';

class Banner extends Component {
  render() {
    let bannerContent = null, bgType = null, particles = false, containerType = null, justification = null, imageDivType = null, id = "home";
    let sectionClass = "section pp-scrollable effect-section gray-bg", sectionStyle={height:"100%"};
    let bannerImgContent = configData[this.props.jsonKeyToConsider]['bannerImg'];
    let subscriptionText = configData[this.props.jsonKeyToConsider]['subscriptionStatus'] ? <>
      <form className="d-flex flex-row m-5px-b p-1 white-bg input-group">
        <input type="email" className="form-control border-radius-0 border-0" placeholder="you@yoursite.com" />
        <button className="m-btn m-btn-theme2nd flex-shrink-0" type="submit">Get Started</button>
      </form>
      <span className="font-small white-color-light">{configData[this.props.jsonKeyToConsider]['subscriptionText']}</span>
    </> : null;
    switch (this.props.bannerType) {
      case "0":
        bgType = "effect theme-bg effect-skew";
        particles = true;
        containerType = "container container-large";
        justification = "row full-screen align-items-center p-100px-tb";
        imageDivType = "col-lg-7 col-xl-8";
        bannerContent = <>
          <div className="col-12 col-lg-5 col-xl-4 p-50px-tb">
            <h1 className="white-color h1 m-15px-b">{configData[this.props.jsonKeyToConsider]['tagLine']}</h1>
            <p className="font-2 white-color-light m-20px-b">{configData[this.props.jsonKeyToConsider]['description']}</p>
            {subscriptionText}
          </div>
        </>
        break;
      case "12":
          sectionClass = "section bg-center bg-cover bg-fixed effect-section";
          id=this.props.contentType;
          sectionStyle = {backgroundImage:`url(${configData[this.props.jsonKeyToConsider][this.props.contentType]['bgImg']})`};
          bgType = "mask theme-bg opacity-9";
          particles = false;
          containerType = "container";
          justification = "row justify-content-center p-50px-t";
          bannerImgContent = null;
          bannerContent = <>
            <div className="col-lg-8 text-center">
              <h2 className="white-color h1 m-20px-b">{configData[this.props.jsonKeyToConsider][this.props.contentType]['title']}</h2>
            </div>
          </>
          break;
      case "4":
        bgType = "effect effect-bg-75 theme-bg";
        particles = true;
        containerType = "container";
        justification = "row align-items-center justify-content-center m-100px-t";
        imageDivType = "col-lg-12";
        let subTagLinelength = configData[this.props.jsonKeyToConsider]['subTagLine'] ? configData[this.props.jsonKeyToConsider]['subTagLine'].length : 0;
        let newTagLine = <div className="m-40px-b">
          <h1 className="display-4 white-color">
            {configData[this.props.jsonKeyToConsider]['tagLine']}
          </h1>
          <h4 className="white-color">
            {configData[this.props.jsonKeyToConsider]['subTagLine'] && configData[this.props.jsonKeyToConsider]['subTagLine'].map(function (innerData, index) {
              if (subTagLinelength - 1 === index)
                return <>{innerData}</>
              else return <>{innerData}<br /></>
            })}
            {configData[this.props.jsonKeyToConsider]['subTagLineReplace'] ? <u><Typed strings={configData[this.props.jsonKeyToConsider]['subTagLineReplace']['arrayToReplace']} typeSpeed={100} backSpeed={0} backDelay={1000} loop smartBackspace /></u> : null}
          </h4>
        </div>;
        bannerContent = <>
          <div className="col-lg-10 text-center m-60px-t md-m-40px-t">
            {newTagLine}
            <p className="lead white-color-light m-30px-b">{configData[this.props.jsonKeyToConsider]['description']}</p>
            {subscriptionText}
          </div>
        </>
        break;
      case "10":
        bgType = "effect effect-bg opacity-5 bg-cover bg-fixed bg-center";
        sectionClass = "effect-section theme-bg";
        sectionStyle = {backgroundImage:`url(${configData[this.props.jsonKeyToConsider][this.props.contentType]['bgImg']})`};
        containerType = "container";
        justification = "row full-screen align-items-center p-100px-tb";
        // justification = "row full-screen align-items-center justify-content-between lg-m-80px-tb";
        bannerImgContent = configData[this.props.jsonKeyToConsider][this.props.contentType]['bannerImgContent'];
        bannerContent = <>
          <div className="col-12 text-center">
            <h1 className="white-color display-4 m-20px-b">{configData[this.props.jsonKeyToConsider][this.props.contentType]['title']}</h1>
            <div className="btn-bar p-25px-t">
              <a className="m-btn m-btn-theme2nd m-btn-radius" href="/">Back to home page</a>
            </div>
          </div>
        </>;
        break;
      default:
        break;

    }
    return (
      <>
        <section id={id} className={sectionClass} style={sectionStyle} key={id}>
          <div className={bgType}></div>
          {particles ? <div className="particles-box" id="particles-box"></div> : null}
          <div className={containerType}>
            <div className={justification}>
              {bannerContent}
              {bannerImgContent ? <div className={imageDivType}>
                <img className="max-width-auto" src={bannerImgContent} title="" alt="" style={{width: "100%"}} />
              </div> : null}
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Banner;