import React, { Component } from 'react';
import Moment from 'react-moment';
import getFeaturedImage from "../helper";
import { Link } from "react-router-dom";
var axios = require('axios');
// import configData from '../config.json';

class ListBLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            blogArray: []
        }
    }
    componentWillMount() {
        var config = {
            method: 'get',
            url: 'https://cdn.contentful.com/spaces/dmsh6kty6h8d/environments/master/entries?access_token=dK31oWHhdvpUSbz2iahqW4YTcuK6F6d_y_FH1o2K66Q',
            headers: {}
        };

        axios(config)
            .then(function (response) {

                response.data.items.map(function (blogContent) {
                    if (blogContent.fields.featuredImage) {
                        getFeaturedImage(blogContent.fields.featuredImage.sys.id);
                    }
                    return null;
                })

                this.setState({
                    blogArray: response.data.items
                })
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            });

    }
    render() {
        let blogData = null;
        if (this.state.blogArray && this.state.blogArray.length > 0) {
            blogData = this.state.blogArray.map(function (blogContent, index) {
                if (blogContent.sys.contentType.sys.id !== "blogs") {
                    return null;
                }

                return (<div className="col-md-6 col-lg-4 m-30px-b" key={`blog_${index}`}>
                    <div className="hover-top card box-shadow-only-hover overflow-hidden">
                        <div>
                            <Link to={`/blog/${blogContent.sys.id}`}>
                                <img id={blogContent.fields.featuredImage.sys.id} title="" alt="" />
                            </Link>
                        </div>
                        <div className="p-20px">
                            <label className="font-small">{blogContent.fields.title}</label>
                            <h5 className="m-10px-b font-w-600"><Link className="dark-color" to={`/blog/${blogContent.sys.id}`}>{blogContent.fields.slug}</Link></h5>
                            <p>{blogContent.fields.description}</p>
                            <div className="nav font-small border-top-1 border-color-dark-gray p-15px-t">
                                <Link className="m-15px-r body-color font-w-500" href={`/blog/${blogContent.sys.id}`}><i className="fas fa-calendar-alt "></i>
                                    {`  `}<Moment format="DD MMM, YYYY">{blogContent.fields.date}</Moment>
                                </Link>
                                {/* <a className="body-color font-w-500" href="#"><i className="fas fa-comments"></i> 8</a> */}
                                <Link className="body-color font-w-500 ml-auto" href={`/blog/${blogContent.sys.id}`}>Read More <i className="fas fa-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>)
            });
        }
        return (
            <>
                <section className="section">
                    <div className="container">
                        <div className="row">
                            {blogData}
                        </div>
                    </div>
                </section>
            </>
        );
    }
};

export default ListBLogs;