import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import Banner from "./Banner";
import GridComponent from "./GridComponent";
import BlogLanding from "./blogs/BlogLanding";
import CareersLanding from "./careers/CareersLanding";
import configData from './config.json';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const key = "rockmetric";

class App extends Component {
  render() {
    return (
      <Router>
        <Header jsonKeyToConsider={key} />
        <main id="pagepiling">
          <Switch>
            <Route exact path="/">
              <Banner jsonKeyToConsider={key} bannerType={configData[key]['bannerType']} />
              {
                Object.keys(configData[key].sectionContent).map(function (innerData, index) {
                  let contentData = configData[key].sectionContent[innerData];
                  return <GridComponent contentData={contentData} jsonKeyToConsider={key} key={`gridcontent_${index}`} />
                })
              }
            </Route>
            <Route path={`/blog/`}>
              <BlogLanding jsonKeyToConsider={key} contentType={"blogSection"} bannerType={"12"} />
            </Route>
            <Route path={`/careers/`}>
              <CareersLanding jsonKeyToConsider={key} contentType={"careerSection"} bannerType={"12"} />
            </Route>
            <Route path={`/privacy`}>
              <CareersLanding jsonKeyToConsider={key} contentType={"privacyPolicy"} bannerType={"12"} />
            </Route>
            <Route path={`/terms-of-use`}>
              <CareersLanding jsonKeyToConsider={key} contentType={"termsCondition"} bannerType={"12"} />
            </Route>
            <Route>
              <Banner jsonKeyToConsider={key} contentType={"error"} bannerType={"10"} />
            </Route>
          </Switch>
        </main>
        <Footer jsonKeyToConsider={key} />
      </Router>
    );
  }
}

export default App;