import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Banner from "../Banner";
import CareersList from './CareersList';
import RenderCareer from './RenderCareer';
import ListDetails from '../ListDetails';
import configData from '../config.json';

class CareersLanding extends Component {
  render() {

    return (
      <Router>
        <Switch>
          <Route exact path="/careers">
            <Banner jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} bannerType={this.props.bannerType} />
            <CareersList jsonKeyToConsider={this.props.jsonKeyToConsider} />
          </Route>
          <Route exact path="/privacy">
            <Banner jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} bannerType={this.props.bannerType} />
            <ListDetails jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} configData={configData} />
          </Route>
          <Route exact path="/terms-of-use">
            <Banner jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} bannerType={this.props.bannerType} />
            <ListDetails jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} configData={configData} />
          </Route>
          <Route path="/careers/all">
            <Banner jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} bannerType={this.props.bannerType} />
            <CareersList jsonKeyToConsider={this.props.jsonKeyToConsider} />
          </Route>
          <Route path="/careers">
            <RenderCareer jsonKeyToConsider={this.props.jsonKeyToConsider} />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default CareersLanding;