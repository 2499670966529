import React, { Component } from 'react';
// import GridComponent from '../GridComponent';

const ListDetails = ({ jsonKeyToConsider, contentType, configData }) => {
    return (
        <section className="section" id={configData[jsonKeyToConsider][contentType]['section']['id']}>
            <div className="container">
                <div className="row align-items-start">
                    <div className="col-lg-12 md-m-15px-tb">
                        <div className="card">
                            <div className="card-body">
                                <div className="m-30px-b">
                                    {
                                        configData[jsonKeyToConsider][contentType]['section']['contentData'].map(function(innerData){
                                            let pData = innerData.p.map(function(pInnerData){
                                                return <p>{pInnerData}</p>
                                            })
                                            let hData = innerData.h3 ? <h3 className="h4">{innerData.h3}</h3> : null
                                            return (
                                                <>
                                                    {hData}
                                                    {pData}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ListDetails;