import React, { Component } from 'react';
import configData from './config.json';
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    let thisYear = (new Date()).getFullYear();
    return (
      <>
        <footer className="dark-bg footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-12 m-15px-tb mr-auto">
                  <div className="m-20px-b">
                    <img src={configData[this.props.jsonKeyToConsider]['logo-light']} title="" alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 m-15px-tb">
                </div>
                <div className="col-lg-3 col-sm-6 m-15px-tb">
                  <h6 className="white-color">
                    Navigation
                  </h6>
                  <ul className="list-unstyled links-white footer-link-1">
                    {
                      configData[this.props.jsonKeyToConsider]['navPage'].map(function (innerData) {
                        let newTab = innerData.newTab ? "_blank" : null;
                        let to = innerData.newTab ? { pathname: innerData.link } : innerData.link;
                        return (<li><Link to={to} target={newTab}>{innerData.name}</Link></li>)
                      })
                    }
                  </ul>
                </div>
                <div className="col-lg-3 col-sm-6 m-15px-tb">
                  <h6 className="white-color">
                    Information
                        </h6>
                  <address>
                    <p className="white-color-light m-5px-b">{configData[this.props.jsonKeyToConsider]['address']}</p>
                    <p className="m-5px-b"><a className="theme2nd-color border-bottom-1 border-color-theme2nd" href="mailto:support@domain.com">{configData[this.props.jsonKeyToConsider]['supportEmail']}</a></p>
                    <p className="m-5px-b"><a className="theme2nd-color border-bottom-1 border-color-theme2nd" href="tel:820-885-3321">{configData[this.props.jsonKeyToConsider]['contactNumber']}</a></p>
                  </address>
                  <div className="social-icon si-30 theme2nd nav">
                    {
                      configData[this.props.jsonKeyToConsider]['social'].map(function (socialData, index) {
                        return <a href={socialData.link} key={`socialIcon_${index}`}><i className={socialData.img}></i></a>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom footer-border-light">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-md-right">
                  <ul className="nav justify-content-center justify-content-md-start m-5px-tb links-white font-small footer-link-1">
                    <li><Link className="nav-link" to={'/privacy'}>Privace &amp; Policy</Link></li>
                    <li><Link className="nav-link" to={'/terms-of-use'}>Terms and Condition</Link></li>
                    {/* <li><a href="/">Get a Quote</a></li> */}
                  </ul>
                </div>
                <div className="col-md-6 text-center text-md-right">
                  <p className="m-0px font-small white-color-light">© {thisYear} ROCKMETRIC ALL RIGHT RESERVED</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
