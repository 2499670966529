import React, { Component } from 'react';
import GridComponent from '../GridComponent';
import configData from '../config.json';
var axios = require('axios');

class CareersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            careerArray: []
        }
    }
    componentWillMount() {
        var config = {
            method: 'get',
            url: 'https://cdn.contentful.com/spaces/dmsh6kty6h8d/environments/master/entries?access_token=dK31oWHhdvpUSbz2iahqW4YTcuK6F6d_y_FH1o2K66Q',
            headers: {}
        };

        axios(config)
            .then(function (response) {

                this.setState({
                    careerArray: response.data.items
                })
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            });

    }
    render() {
        let contentData = null

        if (this.state.careerArray && this.state.careerArray.length > 0) {
            let contentDataArray = configData[this.props.jsonKeyToConsider]['careerSection']['section'];
            contentDataArray["contentData"] = [];

            this.state.careerArray.map(function (careerContent, index) {

                if (careerContent.sys.contentType.sys.id !== "career") {
                    return null;
                }
                let sampleContent = {};
                sampleContent["class"] = "col-lg-4 m-15px-tb";
                sampleContent["hoverEffect"] = true;
                sampleContent["headingType"] = "h4";
                sampleContent["subHeading"] = [careerContent['fields']['subHeading'],careerContent['fields']['address']];
                sampleContent["button"] = {
                    "link": `/careers/${careerContent.sys.id}`,
                    "name": "View"
                }
                sampleContent['tags'] = careerContent['fields']['tags'].slice(0, 2);
                contentDataArray['contentData'].push({ ...careerContent['fields'], ...sampleContent });
                return null;
            });

            contentData = <GridComponent contentData={contentDataArray} jsonKeyToConsider={this.props.jsonKeyToConsider} key={`gridcontent_career_layout`} />
        }

        return (
            <>
                {contentData}
            </>
        )
    }
}

export default CareersList;