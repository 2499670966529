import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ListBLogs from './ListBLogs';
import RenderBlog from './RenderBlog';
import Banner from "../Banner";

class BlogLanding extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/blog">
            <Banner jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} bannerType={this.props.bannerType} />
            <ListBLogs />
          </Route>
          <Route path="/blog/all">
            <Banner jsonKeyToConsider={this.props.jsonKeyToConsider} contentType={this.props.contentType} bannerType={this.props.bannerType} />
            <ListBLogs />
          </Route>
          <Route path="/blog">
            <RenderBlog />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default BlogLanding;