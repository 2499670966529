var axios = require('axios');

async function getFeaturedImage(id, type = "normal") {

    var config = {
        method: 'get',
        url: `https://cdn.contentful.com/spaces/dmsh6kty6h8d/environments/master/assets/${id}?access_token=dK31oWHhdvpUSbz2iahqW4YTcuK6F6d_y_FH1o2K66Q`,
        headers: {}
    };

    axios(config)
        .then(function (response) {
            if (type === "normal")
                document.getElementById(id).src = response.data.fields.file.url;
            else if (type === "backgroundImage"){
                document.getElementById(id).style.backgroundImage = `url(${response.data.fields.file.url})`;
            }
        })
        .catch(function (error) {
            console.log(error);
        });

}

export default getFeaturedImage;