import React, { Component } from 'react';
import configData from './config.json';
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <>
        <div id="loading">
          <div className="load-circle"><span className="one"></span></div>
        </div>
        <header className="header-nav header-white">
          <div className="fixed-header-bar">
            <div className="container container-large">
              <div className="navbar navbar-default navbar-expand-lg main-navbar">
                <div className="navbar-brand">
                  <a href="/" title="Mombo" className="logo">
                    <img src={configData[this.props.jsonKeyToConsider]['logo-light']} className="light-logo" alt="Mombo" title="" style={{ width: "180px" }} />
                    <img src={configData[this.props.jsonKeyToConsider]['logo']} className="dark-logo" alt="Mombo" title="" style={{ width: "180px" }} />
                  </a>
                </div>
                <div className="navbar-collapse justify-content-end collapse" id="navbar-collapse-toggle">
                  <ul className="nav navbar-nav m-auto">
                    {
                      configData[this.props.jsonKeyToConsider]['navPage'].map(function (innerData) {
                        let newTab = innerData.newTab ? "_blank" : null;
                        let to = innerData.newTab ? { pathname: innerData.link } : innerData.link;
                        return (<li><Link className="nav-link" to={to} target={newTab}>{innerData.name}</Link></li>)
                        // return (<li><a className="nav-link" href={innerData.link} target={newTab} >{innerData.name}</a></li>)
                      })
                    }
                  </ul>
                </div>
                <div className="extra-menu d-flex align-items-center">
                  <button type="button" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbar-collapse-toggle" aria-expanded="false" aria-label="nav-icon">
                    <span className="icon-bar"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
};

export default Header;