import React, { Component } from 'react';
import getFeaturedImage from "../helper";


class RenderImage extends Component {
    render() {
        getFeaturedImage(this.props.imageId);
        return (
            <img id={this.props.imageId} title="" alt={this.props.imageId} />
        );
    }
}

export default RenderImage;
