import React, { Component } from 'react';
import configData from './config.json';
import { Link } from "react-router-dom";

var axios = require('axios');

class GridComponent extends Component {
  constructor(props) {
    super(props);
    this.formSubmit = this.formSubmit.bind(this);
  }
  getHeadingContent(contentData, type = "half") {
    if (type === "half") {
      return <>
        <h2 className="m-20px-b">{contentData}</h2>
        <div className="hr-line m-20px-b"> <span className="theme-bg theme2nd-color"></span> </div>
      </>;
    } else if (type === "h4") {
      return <>
        <h4 className="m-20px-b">{contentData}</h4>
        <div className="hr-line m-20px-b"> <span className="theme-bg theme2nd-color"></span> </div>
      </>;
    } else {
      return <>
        <h3 className="h1 m-20px-b p-20px-b theme-after after-50px">{contentData}</h3>
      </>;
    }
  }
  getSubHeadingContent(contentData, type = "half") {
    if (type === "half") {
      return <>
        {typeof contentData == "object" ?
          contentData.map(function (subHeading, index) {
            return (<p className="m-10px-b" key={`subheading_${index}`}>{subHeading}</p>)
          })
          : null
        }
      </>
    } else {
      return <>
        <p className="m-0px font-2">{contentData}</p>
      </>;
    }
  }
  createBullets(contentData) {
    return <ul className="list-type-02 m-5px-b">
      {contentData.map(function (bulletContent, index) {
        return <li key={`${index}`}><i className="fas fa-check"></i>{bulletContent}</li>
      })}
    </ul>;
  }
  createPTags(contentData) {
    return <>
      {contentData.map(function (bulletContent, index) {
        let className = "";
        if (index === 0)
          className = "font-2";
        return <p className={className} key={`ptagContent_${bulletContent}`}>{bulletContent}</p>
      })}
    </>;
  }
  createImage(imageContent) {
    return <img src={imageContent} title="" alt="" />
  }
  createButton(buttonContent) {
    return <div>
      <Link className="m-btn m-btn-radius m-btn-theme2nd" to={buttonContent.link}>{buttonContent.name}</Link>
    </div>
  }
  createFooter() {
    return (
      <div className="border-all-10 border-color-white p-40px-tb p-20px-lr theme-bg box-shadow h-100">
        <h5 className="font-1 white-color m-10px-b">Our Address Info</h5>
        <p className="white-color-light m-30px-b">{configData[this.props.jsonKeyToConsider]['address']}</p>
        <h5 className="font-1 white-color m-10px-b">Our Contact Info</h5>
        <p className="m-5px-b"><a className="white-color-light" href={`mailto:${configData[this.props.jsonKeyToConsider]['contactNumber']}`}>{configData[this.props.jsonKeyToConsider]['supportEmail']}</a></p>
        <p className="m-0px"><a className="white-color-light" href={`tel:${configData[this.props.jsonKeyToConsider]['contactNumber']}`}>{configData[this.props.jsonKeyToConsider]['contactNumber']}</a></p>

        <h5 className="font-1 white-color m-10px-b m-30px-t">Follow Us</h5>
        <div className="social-icon si-30 white radius nav">
          {
            configData[this.props.jsonKeyToConsider]['social'].map(function (socialData, index) {
              return <a href={socialData.link} key={`socialIcon_${index}`}><i className={socialData.img}></i></a>
            })
          }
        </div>
      </div>
    )
  }
  generateForm(contentData) {
    let formData = contentData['formArray'].map(function (getFormContent, index) {
      if (getFormContent['hide']) {
        return null;
      }
      let inputContent = null;
      switch (getFormContent['type']) {
        case "text":
          inputContent = <input id={getFormContent['id']} type="text" name={getFormContent['id']} placeholder={getFormContent['placeholder']} data-constraints="@Required" className="form-control form-control-has-validation form-control-last-child" />
          break;
        case "email":
          inputContent = <input id={getFormContent['id']} type="email" name={getFormContent['id']} placeholder={getFormContent['placeholder']} data-constraints="@Required" className="form-control form-control-has-validation form-control-last-child" />
          break;
        case "textbox":
          inputContent = <textarea className="form-control form-control-has-validation form-control-last-child" id={getFormContent['id']} name={getFormContent['name']} rows="3" placeholder={getFormContent['placeholder']} data-constraints="@Required"></textarea>
          break;
        case "select":
          inputContent = <select id={getFormContent['id']} className="form-control form-control-has-validation form-control-last-child">
            {getFormContent['value'].map(function (innerData, index) {
              return <option value={innerData} key={innerData}>{innerData}</option>;
            })}
          </select>
          break;
        case "submit":
          inputContent = <>
            <button className="m-btn m-btn-theme m-btn-radius" type={getFormContent["type"]} name={getFormContent['id']} id={getFormContent['id']}>{getFormContent['name']}</button>
            <div className="snackbars" id="form-output-global"></div>
          </>
          break;
        default:
          inputContent = <input id={getFormContent['id']} type="text" name={getFormContent['id']} placeholder={getFormContent['placeholder']} data-constraints="@Required" className="form-control form-control-has-validation form-control-last-child" />
          break;
      }
      return (
        <div className={getFormContent['class']}>
          <div className="form-group">
            {getFormContent['type'] === "submit" ? null : <label htmlFor={getFormContent['id']} className="form-control-label">{getFormContent['name']}</label>}
            {inputContent}
          </div>
        </div>
      )
    })
    return (
      <div className="white-bg box-shadow p-30px">
        <div className="p-20px-b">
          <h5 className="m-0px">{contentData.heading}</h5>
        </div>
        <form className="rd-mailform" method="post" autoComplete='off' id="contactForm" onSubmit={this.formSubmit.bind(this, contentData)} noValidate={`validate`}>
          <div className="row">
            {formData}
          </div>
        </form>
      </div>
    )
  }
  formSubmit(formData, e) {
    let finalArray = [], lengthForm = 0;
    formData.formArray.map(function (getFormContent, index) {
      if (getFormContent['hide']) {
        return null;
      }
      switch (getFormContent['type']) {
        case "submit":
          break;
        default:
          let sampleValue = document.getElementById(getFormContent['id']).value;
          if (sampleValue) {
            let element = {};
            element["property"] = getFormContent['id'];
            element["value"] = sampleValue;
            finalArray.push(element)
          }
          lengthForm++;
          break;
      }
    });
    if (finalArray.length === lengthForm) {

      let postData = {
        "method": "POST",
        "url": "https://api.hubapi.com/contacts/v1/contact/?hapikey=99b318c1-de34-4223-92f2-8d88cec6a472",
        "postData": { "properties": finalArray, json: true },
        "header": [ "content-type: application/json" ]
      }

      var config = {
        method: 'POST',
        url: 'https://api.rockmetric.com/system/hitCURL',
        headers: {
          'authorization': 'Basic RVBhSHJsMGxTblh5TUt4bE02bGlZem9yYXZBMWNJajI6Lg==',
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(postData)
      };

      axios(config)
        .then(function (response) {
          debugger
        }.bind(this))
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  tagRender(tagData) {
    return (
      <div className="nav tag-cloud p-25px-t">
        {
          tagData.map(function (innerData, index) {
            return <a href="javascript:void(0)" key={innerData}>{innerData}</a>
          })
        }
      </div>);
  }
  contentIcon(contentIcon) {
    return (
      <div className="icon-80 border-radius-effect yellow-bg white-color d-inline-block m-20px-b">
        <i className={contentIcon}></i>
      </div>
    )
  }
  render() {
    if (this.props.contentData["display"] === false) {
      return null;
    }
    let contentAlignment = this.props.contentData['contentClass'] ? this.props.contentData['contentClass'] : "row justify-content-around align-items-center";
    return (
      <>
        <section id={this.props.contentData['id']} className={`section pp-scrollable ${this.props.contentData['backgroundColor']}`} key={this.props.contentData['id']} style={{ height: "100%" }}>
          <div className="container">
            <div className="row md-m-25px-b m-45px-b justify-content-center text-center">
              {this.props.contentData['heading'] ? this.getHeadingContent(this.props.contentData['heading'], "full") : null}
              {this.props.contentData['subHeading'] ? this.getSubHeadingContent(this.props.contentData['subHeading'], "full") : null}
            </div>
            <div className={contentAlignment}>
              {
                this.props.contentData['contentData'].map(function (sectionContentData) {
                  return (
                    <div className={`${sectionContentData['class'] ? sectionContentData['class'] : "col-lg-6 col-md-6"} md-m-30px-b`}>
                      <div className={`${sectionContentData['hoverEffect'] ? "price-table-02 hover-top box-shadow-hover border-all-1 border-color-gray" : ""}`}>
                        {sectionContentData['type'] === "footer" ? this.createFooter() : null}
                        {sectionContentData['formContent'] ? this.generateForm(sectionContentData['formContent']) : null}
                        {sectionContentData['contentIcon'] ? this.contentIcon(sectionContentData['contentIcon']) : null}
                        {sectionContentData['heading'] ? this.getHeadingContent(sectionContentData['heading'], sectionContentData['headingType']) : null}
                        {sectionContentData['tags'] ? this.tagRender(sectionContentData['tags']) : null}
                        {sectionContentData['subHeading'] ? this.getSubHeadingContent(sectionContentData['subHeading']) : null}
                        {sectionContentData['bullets'] ? this.createBullets(sectionContentData['bullets']) : null}
                        {sectionContentData['pTags'] ? this.createPTags(sectionContentData['pTags']) : null}
                        {sectionContentData['image'] ? this.createImage(sectionContentData['image']) : null}
                        {sectionContentData['button'] ? this.createButton(sectionContentData['button']) : null}
                      </div>
                    </div>)
                }.bind(this))
              }

            </div>
          </div>
        </section>
      </>
    );
  }
};

export default GridComponent;